import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './PageHeader.module.scss'

const PageHeader = ({}) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <h1>GIS Formats</h1>
            <p>Comprensive list of GIS formats for your geospatial data</p>
          </div>
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
