import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import PageHeader from '../components/formats/PageHeader'
import Grid from '../components/formats/Grid'
import Layout from '../components/common/Layout'

const FormatsPage = ({ data }) => {
  const formats = data.formats.nodes
  return (
    <Layout
      title="GIS Formats - Data formats for your geospatial data"
      meta={{
        description:
          'Comprensive list of GIS formats for your geospatial data. Explore the best formats for your data and start creating your maps.',
        title: 'GIS Formats - Data formats for your geospatial data',
        absoluteImageUrl: 'https://www.atlas.co/images/data-formats.png',
        type: 'website'
      }}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <Grid formats={formats} />
        </Fade>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    formats: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { fileAbsolutePath: { regex: "/formats/" } }
      limit: 100
    ) {
      nodes {
        id
        frontmatter {
          title
          excerpt
          short
        }
      }
    }
  }
`

export default FormatsPage
