import React, { useState, useEffect } from 'react'
import { Select, Skeleton, TextInput } from '@mantine/core'
import styles from './Grid.module.scss'
import { Link } from 'gatsby'
import _ from 'lodash'

const Grid = ({ formats }) => {
  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div>
            <Skeleton visible={!formats}>
              <div className={styles.grid}>
                {formats.map(m => (
                  <GridItem
                    title={m.frontmatter.title}
                    excerpt={m.frontmatter.excerpt}
                    short={m.frontmatter.short}
                    link={`/formats/${_.kebabCase(m.frontmatter.title)}/`}
                  />
                ))}
              </div>
            </Skeleton>
          </div>
        </div>
      </div>
    </>
  )
}

const GridItem = ({ title, link, short, excerpt }) => {
  const [hover, setHover] = useState(false)
  return (
    <Link to={link}>
      <div
        className={styles.postCard}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div className={`${styles.cardContent} ${hover && styles.hovered}`}>
          <span className={styles.tag}>{short}</span>
          <h3>{title}</h3>
          <p>{excerpt}</p>
        </div>
      </div>
    </Link>
  )
}

export default Grid
